var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isMobile() && _vm.currentUser.superAdmin)?_c('div',[_c('div',{staticClass:"topbar-item mb-6"},[_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('router-link',{attrs:{"to":{ name: 'add-new-shop-post' }}},[_c('v-btn',{staticClass:"ma-2 white--text p-4",attrs:{"color":"green darken-1"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" NEW POST ")],1)],1)],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shopPosts,"options":_vm.pagination,"server-items-length":_vm.totalShopPosts,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.createDate))+" ")]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'ShopPostEdit',
            params: { shopPostId: item.id, shopPostName: item.title }
          }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.delete",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":"","disabled":_vm.loading},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete ")])],1)]}},{key:"item.gender",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_c('v-select',{attrs:{"items":_vm.genders,"item-text":"text","item-value":"value","return-object":"","single-line":"","disabled":_vm.loading},on:{"change":function($event){return _vm.changeGender(item)}},model:{value:(item.gender),callback:function ($$v) {_vm.$set(item, "gender", $$v)},expression:"item.gender"}})],1)]}},{key:"item.cities",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.loadCitiesForPost(item)}}},on),[_vm._v("mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((_vm.cities),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.productPicture",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[(
            item != null &&
              item.shopProduct != null &&
              item.shopProduct.media != null &&
              item.shopProduct.media.length > 0
          )?_c('v-img',{attrs:{"src":item.shopProduct.media[0].fullThumbPath1,"contain":"","max-height":"100","max-width":"100"},on:{"error":function($event){item.shopProduct.media[0].fullThumbPath1 = 'media/error/imageNotFound.png'},"click":function($event){return _vm.goToGallery(item.shopProduct.media)}}},[(item.shopProduct.media != null && item.shopProduct.media.length > 1)?_c('v-avatar',{attrs:{"right":"","color":"indigo","size":"22","rounded":""}},[(item.shopProduct.media != null && item.shopProduct.media.length > 1)?_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.shopProduct.media.length))]):_vm._e()]):_vm._e()],1):_vm._e()],1)]}},{key:"item.description",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.description != null && item.description.length > 20 ? item.description.substring(0, 20) : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.description != null && item.description.length > 20)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.description))])])],1)],1)]}},{key:"item.productDiscount",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.discount * 100 + "%")+" ")])]}},{key:"item.productPrice",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_c('span',{staticClass:"old-price"},[_vm._v(_vm._s(item.shopProduct != null ? item.shopProduct.price + "€" : ""))]),_vm._v(" "+_vm._s(item.shopProduct != null ? item.price + "€" : "")+" ")])]}},{key:"item.categories",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopProduct.shopCategories != null && item.shopProduct.shopCategories.length > 0 ? item.shopProduct.shopCategories[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.shopProduct.shopCategories.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.shopProduct.shopCategories),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.published",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.publishShopPost(item.id, item.published)}},model:{value:(item.published),callback:function ($$v) {_vm.$set(item, "published", $$v)},expression:"item.published"}})]}},{key:"item.editorsChoice",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.setShopPostForEditorsChoice(item.id, item.editorsChoice)}},model:{value:(item.editorsChoice),callback:function ($$v) {_vm.$set(item, "editorsChoice", $$v)},expression:"item.editorsChoice"}})]}},{key:"item.shopProduct",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":"","disabled":_vm.loading},on:{"click":function($event){return _vm.goToProductDialog(item.shopProduct)}}},[_vm._v("mdi-tag-text-outline ")])],1)]}},{key:"item.categoryPicker",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":"","disabled":_vm.loading},on:{"click":function($event){return _vm.goToCategoryPickerDialog(item)}}},[_vm._v("mdi-checkbox-marked ")])],1)]}},{key:"item.tags",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
            name: 'ShopPostTags',
            params: { shopPostId: item.id }
          }}},[_c('v-avatar',{attrs:{"color":"green","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":"","disabled":_vm.loading}},[_vm._v("mdi-tag")])],1)],1)],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopPosts();}},model:{value:(_vm.shopName),callback:function ($$v) {_vm.shopName=$$v},expression:"shopName"}})],1),_c('td',[_c('v-menu',{ref:"dateRangeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-text-field',attrs,false),on))]}}],null,false,3580630307),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateRangeMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dateRangeMenu.save(_vm.dates);
                  _vm.retrieveShopPosts();}}},[_vm._v(" OK ")])],1)],1)],1),_c('td',[_c('v-select',{attrs:{"items":_vm.cityShoppingChoiceList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopPosts();}},model:{value:(_vm.cityShopping),callback:function ($$v) {_vm.cityShopping=$$v},expression:"cityShopping"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopPosts();}},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=$$v},expression:"productName"}})],1),_c('td',[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.gendersFilter,"item-text":"text","item-value":"value"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopPosts();}},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopPosts();}},model:{value:(_vm.productDescription),callback:function ($$v) {_vm.productDescription=$$v},expression:"productDescription"}})],1),_c('td',[_c('v-text-field',{staticClass:"shrink mx-4",attrs:{"hide-details":"","single-line":"","type":"number"},model:{value:(_vm.discountFrom),callback:function ($$v) {_vm.discountFrom=$$v},expression:"discountFrom"}}),_c('v-text-field',{staticClass:"shrink mx-4",attrs:{"hide-details":"","single-line":"","type":"number"},model:{value:(_vm.discountTo),callback:function ($$v) {_vm.discountTo=$$v},expression:"discountTo"}})],1),_c('td',[_c('v-text-field',{staticClass:"shrink mx-4",attrs:{"hide-details":"","single-line":"","type":"number"},model:{value:(_vm.priceFrom),callback:function ($$v) {_vm.priceFrom=$$v},expression:"priceFrom"}}),_c('v-text-field',{staticClass:"shrink mx-4",attrs:{"hide-details":"","single-line":"","type":"number"},model:{value:(_vm.priceTo),callback:function ($$v) {_vm.priceTo=$$v},expression:"priceTo"}})],1),_c('td',[_c('v-autocomplete',{attrs:{"items":_vm.userCities,"loading":_vm.citiesIsLoading,"search-input":_vm.search,"clearable":"","item-text":"name","item-value":"id","label":"City"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopPosts();}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("cities")])])],1)]},proxy:true},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]]}}],null,false,2292246050),model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopPosts();}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.postPublishedList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopPosts();}},model:{value:(_vm.postPublished),callback:function ($$v) {_vm.postPublished=$$v},expression:"postPublished"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveShopPosts();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)])]},proxy:true}],null,false,2637763419)}),_c('ConfirmDialog',{ref:"confirm"}),_c('ProductDialog',{attrs:{"shopProduct":_vm.shopProduct},on:{"close":function($event){_vm.showProductDialog = false}},model:{value:(_vm.showProductDialog),callback:function ($$v) {_vm.showProductDialog=$$v},expression:"showProductDialog"}}),_c('CategoryPickerDialog',{attrs:{"productPost":_vm.productPost,"categories":_vm.categoriesTree,"selectedCategories":_vm.selectedCategories},on:{"close":function($event){_vm.showCategoryPickerDialog = false},"update-selectedCategories":_vm.updateSelectedCategories},model:{value:(_vm.showCategoryPickerDialog),callback:function ($$v) {_vm.showCategoryPickerDialog=$$v},expression:"showCategoryPickerDialog"}}),_c('GalleryDialog',{attrs:{"mediaList":_vm.mediaList},on:{"close":function($event){_vm.showGalleryDialog = false}},model:{value:(_vm.showGalleryDialog),callback:function ($$v) {_vm.showGalleryDialog=$$v},expression:"showGalleryDialog"}})],1):(_vm.isMobile() && _vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersMobile,"items":_vm.shopPosts,"options":_vm.pagination,"server-items-length":_vm.totalShopPosts,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.cities",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.loadCitiesForPost(item)}}},on),[_vm._v("mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((_vm.cities),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.productPicture",fn:function(ref){return undefined}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.description != null && item.description.length > 20 ? item.description.substring(0, 20) : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.description != null && item.description.length > 20)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.description))])])],1)],1)]}},{key:"item.productDiscount",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.discount * 100 + "%")+" ")])]}},{key:"item.productPrice",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_c('span',{staticClass:"old-price"},[_vm._v(_vm._s(item.shopProduct != null ? item.shopProduct.price + "€" : ""))]),_vm._v(" "+_vm._s(item.shopProduct != null ? item.price + "€" : "")+" ")])]}},{key:"item.categories",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopProduct.shopCategories != null && item.shopProduct.shopCategories.length > 0 ? item.shopProduct.shopCategories[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.shopProduct.shopCategories.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.shopProduct.shopCategories),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.published",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{on:{"click":function($event){return _vm.publishShopPost(item.id, item.published)}},model:{value:(item.published),callback:function ($$v) {_vm.$set(item, "published", $$v)},expression:"item.published"}})]}},{key:"item.editorsChoice",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{on:{"click":function($event){return _vm.setShopPostForEditorsChoice(item.id, item.editorsChoice)}},model:{value:(item.editorsChoice),callback:function ($$v) {_vm.$set(item, "editorsChoice", $$v)},expression:"item.editorsChoice"}})]}},{key:"item.shopProduct",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.goToProductDialog(item.shopProduct)}}},[_vm._v("mdi-tag-text-outline ")])],1)]}},{key:"item.categoryPicker",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.goToCategoryPickerDialog(item)}}},[_vm._v("mdi-checkbox-marked ")])],1)]}},{key:"body.prepend",fn:function(){return [_c('div',{staticClass:"filter"},[_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"shop name"},model:{value:(_vm.shopName),callback:function ($$v) {_vm.shopName=$$v},expression:"shopName"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"category"},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.postPublishedList,"dense":"","hide-details":"","label":"Published status"},model:{value:(_vm.postPublished),callback:function ($$v) {_vm.postPublished=$$v},expression:"postPublished"}})],1),_c('div',{staticClass:"search"},[_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                  _vm.retrieveShopPosts();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                  _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)])])]},proxy:true}])}),_c('ProductDialog',{attrs:{"shopProduct":_vm.shopProduct},on:{"close":function($event){_vm.showProductDialog = false}},model:{value:(_vm.showProductDialog),callback:function ($$v) {_vm.showProductDialog=$$v},expression:"showProductDialog"}}),_c('CategoryPickerDialog',{attrs:{"productPost":_vm.productPost,"categories":_vm.categoriesTree,"selectedCategories":_vm.selectedCategories},on:{"close":function($event){_vm.showCategoryPickerDialog = false},"update-selectedCategories":_vm.updateSelectedCategories},model:{value:(_vm.showCategoryPickerDialog),callback:function ($$v) {_vm.showCategoryPickerDialog=$$v},expression:"showCategoryPickerDialog"}}),_c('GalleryDialog',{attrs:{"mediaList":_vm.mediaList},on:{"close":function($event){_vm.showGalleryDialog = false}},model:{value:(_vm.showGalleryDialog),callback:function ($$v) {_vm.showGalleryDialog=$$v},expression:"showGalleryDialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }