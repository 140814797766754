<template>
  <div v-if="!isMobile() && currentUser.superAdmin">
    <div class="topbar-item mb-6">
      <div class="d-flex flex-row-reverse">
        <router-link :to="{ name: 'add-new-shop-post' }">
          <v-btn color="green darken-1" class="ma-2 white--text p-4">
            <v-icon dark>mdi-plus</v-icon>
            NEW POST
          </v-btn>
        </router-link>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="shopPosts"
      :options.sync="pagination"
      :server-items-length="totalShopPosts"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.createDate="{ item }">
        {{ parseDate(item.createDate) }}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
            :to="{
              name: 'ShopPostEdit',
              params: { shopPostId: item.id, shopPostName: item.title }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>

      <template v-slot:item.delete="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)" :disabled="loading">mdi-delete </v-icon>
        </v-avatar>
      </template>

      <template v-slot:item.gender="{ item }">
        <td class="text-xs-right">
          <v-select
            v-model="item.gender"
            :items="genders"
            item-text="text"
            item-value="value"
            return-object
            single-line
            v-on:change="changeGender(item)"
            :disabled="loading"
          ></v-select>
        </td>
      </template>
      <template v-slot:item.cities="{ item }">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon @click="loadCitiesForPost(item)" small class="mr-2" v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in cities" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.productPicture="{ item }">
        <td class="text-xs-right">
          <v-img
            v-if="
              item != null &&
                item.shopProduct != null &&
                item.shopProduct.media != null &&
                item.shopProduct.media.length > 0
            "
            :src="item.shopProduct.media[0].fullThumbPath1"
            contain
            max-height="100"
            max-width="100"
            @error="item.shopProduct.media[0].fullThumbPath1 = 'media/error/imageNotFound.png'"
            @click="goToGallery(item.shopProduct.media)"
          >
            <v-avatar
              v-if="item.shopProduct.media != null && item.shopProduct.media.length > 1"
              right
              color="indigo"
              size="22"
              rounded
            >
              <span
                v-if="item.shopProduct.media != null && item.shopProduct.media.length > 1"
                class="white--text"
                >{{ item.shopProduct.media.length }}</span
              >
            </v-avatar>
          </v-img>
        </td>
      </template>
      <template v-slot:item.description="{ item }">
        {{
          item.description != null && item.description.length > 20
            ? item.description.substring(0, 20)
            : ""
        }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="item.description != null && item.description.length > 20"
              small
              class="mr-2"
              v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-card elevation="2">
            <v-card-text>
              <span class="black--text">{{ item.description }}</span>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:item.productDiscount="{ item }">
        <td class="text-xs-right">
          {{ item.discount * 100 + "%" }}
        </td>
      </template>
      <template v-slot:item.productPrice="{ item }">
        <td class="text-xs-right">
          <span class="old-price">{{
            item.shopProduct != null ? item.shopProduct.price + "€" : ""
          }}</span>
          {{ item.shopProduct != null ? item.price + "€" : "" }}
        </td>
      </template>
      <template v-slot:item.categories="{ item }">
        {{
          item.shopProduct.shopCategories != null && item.shopProduct.shopCategories.length > 0
            ? item.shopProduct.shopCategories[0].name
            : ""
        }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.shopProduct.shopCategories.length > 1" small class="mr-2" v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.shopProduct.shopCategories" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.published="{ item }">
        <v-switch
          v-model="item.published"
          @click="publishShopPost(item.id, item.published)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.editorsChoice="{ item }">
        <v-switch
          v-model="item.editorsChoice"
          @click="setShopPostForEditorsChoice(item.id, item.editorsChoice)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.shopProduct="{ item }">
        <v-avatar color="indigo" size="26" rounded>
          <v-icon dark @click="goToProductDialog(item.shopProduct)" :disabled="loading"
            >mdi-tag-text-outline
          </v-icon>
        </v-avatar>
      </template>
      <template v-slot:item.categoryPicker="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <v-icon dark @click="goToCategoryPickerDialog(item)" :disabled="loading"
            >mdi-checkbox-marked
          </v-icon>
        </v-avatar>
      </template>
      <template v-slot:item.tags="{ item }">
        <td>
          <router-link
            :to="{
              name: 'ShopPostTags',
              params: { shopPostId: item.id }
            }"
          >
            <v-avatar color="green" size="26" rounded>
              <v-icon dark :disabled="loading">mdi-tag</v-icon>
            </v-avatar>
          </router-link>
        </td>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field
              v-model="shopName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShopPosts();
              "
            ></v-text-field>
          </td>

          <td>
            <v-menu
              ref="dateRangeMenu"
              v-model="dateRangeMenu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dates" v-bind="attrs" v-on="on" clearable></v-text-field>
              </template>
              <v-date-picker v-model="dates" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateRangeMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.dateRangeMenu.save(dates);
                    retrieveShopPosts();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </td>
          <td>
            <v-select
              :items="cityShoppingChoiceList"
              v-model="cityShopping"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShopPosts();
              "
            ></v-select>
          </td>
          <td>
            <v-text-field
              v-model="productName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShopPosts();
              "
            ></v-text-field>
          </td>
          <td>
            <v-select
              v-model="gender"
              dense
              hide-details
              :items="gendersFilter"
              item-text="text"
              item-value="value"
              @keyup.enter="
                page = 1;
                retrieveShopPosts();
              "
            ></v-select>
          </td>
          <td>
            <v-text-field
              v-model="productDescription"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShopPosts();
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="discountFrom"
              hide-details
              single-line
              type="number"
              class="shrink mx-4"
            />
            <v-text-field
              v-model="discountTo"
              hide-details
              single-line
              type="number"
              class="shrink mx-4"
            />
          </td>
          <td>
            <v-text-field
              v-model="priceFrom"
              hide-details
              single-line
              type="number"
              class="shrink mx-4"
            />
            <v-text-field
              v-model="priceTo"
              hide-details
              single-line
              type="number"
              class="shrink mx-4"
            />
          </td>
          <td>
            <v-autocomplete
              v-model="city"
              :items="userCities"
              :loading="citiesIsLoading"
              :search-input.sync="search"
              clearable
              item-text="name"
              item-value="id"
              label="City"
              @keyup.enter="
                page = 1;
                retrieveShopPosts();
              "
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search for
                    <strong>cities</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </td>
          <td>
            <v-text-field
              v-model="category"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShopPosts();
              "
            ></v-text-field>
          </td>
          <td>
            <v-select
              :items="postPublishedList"
              v-model="postPublished"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShopPosts();
              "
            ></v-select>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveShopPosts();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
    <ProductDialog
      v-model="showProductDialog"
      @close="showProductDialog = false"
      :shopProduct="shopProduct"
    />
    <CategoryPickerDialog
      v-model="showCategoryPickerDialog"
      @close="showCategoryPickerDialog = false"
      @update-selectedCategories="updateSelectedCategories"
      :productPost="productPost"
      :categories="categoriesTree"
      :selectedCategories="selectedCategories"
    />
    <GalleryDialog
      v-model="showGalleryDialog"
      @close="showGalleryDialog = false"
      :mediaList="mediaList"
    />
  </div>
  <div v-else-if="isMobile() && currentUser.superAdmin">
    <v-data-table
      :headers="headersMobile"
      :items="shopPosts"
      :options.sync="pagination"
      :server-items-length="totalShopPosts"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.cities="{ item }">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon @click="loadCitiesForPost(item)" small class="mr-2" v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in cities" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.productPicture="{}">
        <!--        <td class="text-xs-right">
          <v-img
              v-if="
              item != null &&
                item.shopProduct != null &&
                item.shopProduct.media != null &&
                item.shopProduct.media.length > 0
            "
              :src="item.shopProduct.media[0].fullThumbPath1"
              contain
              max-height="100"
              max-width="100"
              @error="
              item.shopProduct.media[0].fullThumbPath1 =
                'media/error/imageNotFound.png'
            "
              @click="goToGallery(item.shopProduct.media)"
          >
            <v-avatar
                v-if="
                item.shopProduct.media != null &&
                  item.shopProduct.media.length > 1
              "
                right
                color="indigo"
                size="22"
                rounded
            >
              <span
                  v-if="
                  item.shopProduct.media != null &&
                    item.shopProduct.media.length > 1
                "
                  class="white&#45;&#45;text"
              >{{ item.shopProduct.media.length }}</span
              >
            </v-avatar>
          </v-img>
        </td>-->
      </template>
      <template v-slot:item.description="{ item }">
        {{
          item.description != null && item.description.length > 20
            ? item.description.substring(0, 20)
            : ""
        }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="item.description != null && item.description.length > 20"
              small
              class="mr-2"
              v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-card elevation="2">
            <v-card-text>
              <span class="black--text">{{ item.description }}</span>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:item.productDiscount="{ item }">
        <td class="text-xs-right">
          {{ item.discount * 100 + "%" }}
        </td>
      </template>
      <template v-slot:item.productPrice="{ item }">
        <td class="text-xs-right">
          <span class="old-price">{{
            item.shopProduct != null ? item.shopProduct.price + "€" : ""
          }}</span>
          {{ item.shopProduct != null ? item.price + "€" : "" }}
        </td>
      </template>
      <template v-slot:item.categories="{ item }">
        {{
          item.shopProduct.shopCategories != null && item.shopProduct.shopCategories.length > 0
            ? item.shopProduct.shopCategories[0].name
            : ""
        }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.shopProduct.shopCategories.length > 1" small class="mr-2" v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.shopProduct.shopCategories" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.published="{ item }">
        <v-switch
          v-model="item.published"
          @click="publishShopPost(item.id, item.published)"
        ></v-switch>
      </template>
      <template v-slot:item.editorsChoice="{ item }">
        <v-switch
          v-model="item.editorsChoice"
          @click="setShopPostForEditorsChoice(item.id, item.editorsChoice)"
        ></v-switch>
      </template>
      <template v-slot:item.shopProduct="{ item }">
        <v-avatar color="indigo" size="26" rounded>
          <v-icon dark @click="goToProductDialog(item.shopProduct)">mdi-tag-text-outline </v-icon>
        </v-avatar>
      </template>
      <template v-slot:item.categoryPicker="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <v-icon dark @click="goToCategoryPickerDialog(item)">mdi-checkbox-marked </v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <div class="filter">
          <td>
            <v-text-field v-model="shopName" type="text" label="shop name"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="category" type="text" label="category"></v-text-field>
          </td>
          <td>
            <v-select
              :items="postPublishedList"
              v-model="postPublished"
              dense
              hide-details
              label="Published status"
            ></v-select>
          </td>
          <div class="search">
            <td>
              <v-avatar color="indigo" size="30">
                <v-icon
                  dark
                  @click="
                    page = 1;
                    retrieveShopPosts();
                  "
                  >mdi-magnify
                </v-icon>
              </v-avatar>
            </td>
            <td>
              <v-avatar color="grey" size="30">
                <v-icon
                  dark
                  @click="
                    page = 1;
                    clearSearch();
                  "
                  >mdi-close
                </v-icon>
              </v-avatar>
            </td>
          </div>
        </div>
      </template>
    </v-data-table>
    <ProductDialog
      v-model="showProductDialog"
      @close="showProductDialog = false"
      :shopProduct="shopProduct"
    />
    <CategoryPickerDialog
      v-model="showCategoryPickerDialog"
      @close="showCategoryPickerDialog = false"
      @update-selectedCategories="updateSelectedCategories"
      :productPost="productPost"
      :categories="categoriesTree"
      :selectedCategories="selectedCategories"
    />
    <GalleryDialog
      v-model="showGalleryDialog"
      @close="showGalleryDialog = false"
      :mediaList="mediaList"
    />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductDialog from "@/view/pages/ProductDialog";
import CategoryPickerDialog from "@/view/pages/CategoryPickerDialog";
import GalleryDialog from "@/view/pages/GalleryDialog";
import { isMobile } from "@/core/services/mobileMixin";
import ShopApiService from "@/core/services/api.service.shop-api";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "shop-posts",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.shopPosts");
    },
    headers() {
      return [
        {
          text: "Shop Id",
          align: "start",
          sortable: true,
          value: "shopId"
        },
        { text: this.$i18n.t("pages.shopPosts.shopName"), value: "shopName", sortable: false },
        {
          text: this.$i18n.t("pages.shopPosts.date"),
          value: "createDate",
          width: "15%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopPosts.productPicture"),
          value: "productPicture",
          sortable: false
        },
        { text: this.$i18n.t("pages.shopPosts.productName"), value: "title", sortable: false },
        {
          text: this.$i18n.t("pages.shopPosts.gender"),
          value: "gender",
          width: "10%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopPosts.productDescription"),
          value: "description",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopPosts.productDiscount"),
          value: "productDiscount",
          sortable: false,
          width: "7%"
        },
        {
          text: this.$i18n.t("pages.shopPosts.productPrice"),
          value: "productPrice",
          sortable: false,
          width: "7%"
        },
        {
          text: this.$i18n.t("pages.shopPosts.city"),
          value: "cities",
          width: "10%",
          sortable: false
        },
        { text: this.$i18n.t("pages.shopPosts.category"), value: "categories", sortable: false },
        {
          text: this.$i18n.t("pages.shopPosts.published"),
          value: "published",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopPosts.editors"),
          value: "editorsChoice",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopPosts.categoryPicker"),
          align: "center",
          value: "categoryPicker",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopPosts.tags"),
          value: "tags",
          align: "center",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopPosts.delete"),
          value: "delete",
          align: "center",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shopPosts.edit"),
          value: "edit",
          align: "center",
          sortable: false
        }
      ];
    },
    genders() {
      return [
        {
          text: this.$i18n.t("gender.male"),
          value: "M"
        },
        {
          text: this.$i18n.t("gender.female"),
          value: "F"
        },
        {
          text: this.$i18n.t("gender.unisex"),
          value: "U"
        }
      ];
    },
    gendersFilter() {
      return [
        {
          text: this.$i18n.t("gender.all"),
          value: "All"
        },
        {
          text: this.$i18n.t("gender.male"),
          value: "M"
        },
        {
          text: this.$i18n.t("gender.female"),
          value: "F"
        },
        {
          text: this.$i18n.t("gender.unisex"),
          value: "U"
        }
      ];
    }
  },
  data() {
    return {
      cities: [],
      gender: "",
      dates: [],
      dateRangeMenu: false,
      readMoreActivated: false,
      shopName: "",
      productName: "",
      productDescription: "",
      productPrice: "",
      discountFrom: 5,
      discountTo: 99,
      priceFrom: null,
      priceTo: null,
      city: "",
      userCities: [],
      citiesIsLoading: false,
      search: "",
      category: "",
      postPublished: "",
      editorsChoice: "",
      cityShopping: "",
      totalShopPosts: 0,
      shopPosts: [],
      loading: false,
      pagination: {},
      showProductDialog: false,
      shopProduct: null,
      showCategoryPickerDialog: false,
      categoriesTree: [],
      selectedCategories: [],
      productPost: null,
      dialogDelete: false,
      showGalleryDialog: false,
      mediaList: [],
      postPublishedList: ["All", "No", "Yes"],
      editorsChoiceList: ["All", "No", "Yes"],
      cityShoppingChoiceList: ["All", "City Shopping"],
      headersMobile: [
        { text: "Shop Name", value: "shopName" },
        { text: "Product Picture", value: "productPicture", sortable: false },
        { text: "Category", value: "categories", sortable: false },
        { text: "Published", value: "published", width: "6%", sortable: false },
        { text: "Category Picker", value: "categoryPicker", sortable: false }
      ],
      errors: []
    };
  },
  components: {
    ProductDialog,
    CategoryPickerDialog,
    GalleryDialog,
    ConfirmDialog
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveShopPosts();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
  },
  async created() {
    await ShopApiService.init();
    this.searchCities();
  },
  methods: {
    searchCities() {
      // Items have already been loaded
      if (this.userCities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.userCities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    parseDate(date) {
      if (date == null) return "";
      return moment
        .utc(date)
        .local()
        .format("yyyy-MM-DD HH:mm:ss");
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.shopPosts.areYouSureDelete") +
            item.shopProduct.name +
            this.$i18n.t("pages.shopPosts.fromEvents")
        )
      ) {
        this.deletePostFromEvents(item);
      }
    },
    loadCitiesForPost(item) {
      this.cities = [];
      ApiService.get(`api/shop-posts/${item.id}/cities`)
        .then(response => {
          this.$log.debug("Cities for post: ", response.data);
          this.cities = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    deletePostFromEvents(item) {
      this.loading = true;
      if (item.id != null) {
        ShopApiService.put(`/hide-product-post/?productPostId=${item.id}`)
          .then(async responseShopApiService => {
            await this.$log.debug("Remote endpoint response: " + responseShopApiService);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.retrieveShopPosts(), (this.loading = false)));
      }
    },
    changeGender(shopPost) {
      let changeGenderRequest = {};
      changeGenderRequest["id"] = shopPost.id;
      changeGenderRequest["gender"] = shopPost.gender.value;
      this.loading = true;
      ApiService.put(`api/shop-posts/change-gender`, changeGenderRequest)
        .then(response => {
          this.$log.debug("Gender updated: " + response);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isMobile,
    goToGallery(mediaList) {
      this.showGalleryDialog = true;
      this.mediaList = mediaList;
    },
    updateSelectedCategories(selectedCategories) {
      this.selectedCategories = selectedCategories;
    },
    goToProductDialog(shopProduct) {
      this.showProductDialog = true;
      this.shopProduct = shopProduct;
    },
    listToTree(list) {
      let map = {},
        node,
        roots = [],
        i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== null && node.parentId !== "0") {
          // if you have dangling branches check that map[node.parentId] exists
          if (
            typeof list[map[node.parentId]].children !== "undefined" &&
            list[map[node.parentId]].children.length >= 0
          ) {
            list[map[node.parentId]].children.push(node);
          } else {
            list[map[node.parentId]].children = []; // initialize the children
            list[map[node.parentId]].children.push(node);
          }
        } else {
          roots.push(node);
        }
      }
      this.categoriesTree = roots;
      console.log("CategoriesTree:" + this.categoriesTree);
    },
    goToCategoryPickerDialog(item) {
      this.loading = true;
      ApiService.get(`api/shop-posts/${item.id}/categories`)
        .then(response => {
          this.$log.debug("ProductCategoriesAndAllCategoryTree: ", response.data);
          this.listToTree(response.data.shopCategoryTree);
          this.productPost = item;
          this.selectedCategories = response.data.productCategories.map(function(shopCategory) {
            return shopCategory.id;
          });
          this.$log.debug("selectedCategories: ", this.selectedCategories);
          this.showCategoryPickerDialog = true;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    publishShopPost(id, publish) {
      this.loading = true;
      ApiService.patch(`api/shop-posts/${id}`, {
        published: publish
      })
        .then(response => {
          this.$log.debug("Published: ", response.data.published);
          this.retrieveShopPosts();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },

    setShopPostForEditorsChoice(id, editorsChoice) {
      this.loading = true;
      ApiService.patch(`api/shop-posts/${id}`, {
        editorsChoice: editorsChoice
      })
        .then(response => {
          this.$log.debug("editorsChoice: ", response.data.editorsChoice);
          this.retrieveShopPosts();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams(
      shopName,
      dates,
      productName,
      productDescription,
      productDiscountFrom,
      productDiscountTo,
      productPriceFrom,
      productPriceTo,
      city,
      category,
      postPublished,
      editorsChoice,
      gender,
      cityShopping
    ) {
      let params = {};

      if (shopName) {
        params["shopName"] = shopName;
      }

      if (dates != null && dates[0] != null) {
        params["dateRange"] = dates.join(",");
      }

      if (productName) {
        params["productName"] = productName;
      }

      if (productDescription) {
        params["productDescription"] = productDescription;
      }

      if (productDiscountFrom) {
        params["productDiscountFrom"] = productDiscountFrom;
      }

      if (productDiscountTo) {
        params["productDiscountTo"] = productDiscountTo;
      }

      if (productPriceFrom) {
        params["productPriceFrom"] = productPriceFrom;
      }

      if (productPriceTo) {
        params["productPriceTo"] = productPriceTo;
      }

      if (city) {
        params["city"] = city;
      }

      if (category) {
        params["category"] = category;
      }

      if (postPublished) {
        params["postPublished"] = postPublished;
      }

      if (editorsChoice) {
        params["editorsChoice"] = editorsChoice;
      }

      if (gender) {
        params["gender"] = gender;
      }

      if (cityShopping) {
        params["cityShopping"] = cityShopping;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      (this.shopName = ""),
        (this.productName = ""),
        (this.productDescription = ""),
        (this.dates = []),
        (this.discountFrom = 5),
        (this.discountTo = 99),
        (this.priceFrom = null),
        (this.priceTo = null),
        (this.city = "");
      this.category = "";
      this.postPublished = "";
      this.editorsChoice = "";
      this.gender = "";
      this.cityShopping = "";
    },

    retrieveShopPosts() {
      const params = this.getRequestParams(
        this.shopName,
        this.dates,
        this.productName,
        this.productDescription,
        this.discountFrom,
        this.discountTo,
        this.priceFrom,
        this.priceTo,
        this.city,
        this.category,
        this.postPublished,
        this.editorsChoice,
        this.gender,
        this.cityShopping
      );
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/shop-posts", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Shop posts: ", response.data.content);
          this.shopPosts = response.data.content;
          this.totalShopPosts = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalShopPosts;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

.old-price {
  text-decoration: line-through;
}

.v-data-table-header th {
  white-space: nowrap;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: aliceblue;
  border: 1px solid brown;
}

.search {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
</style>
