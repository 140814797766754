<template>
  <v-dialog v-model="show" max-width="450px" persistent fullscreen>
  <div class="card card-custom card-stretch">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label  text-dark"
        >{{$t('pages.shopPosts.catPickerFor')}} <strong>{{productPost != null ? productPost.title : ""}}</strong></span
        >
      </h3>
    </div>
    <div>
      <treeselect
          :multiple="true"
          :options="categories"
          :searchable="true"
          placeholder="Select categories..."
          v-model="selectedCategoriesLocal"
      />
<!--      <div class="mt-5 ml-1">-->
<!--        Selected: <strong>{{ selectedCategoriesLocal }}</strong>-->
<!--      </div>-->
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="show = false">{{$t('common.close')}}</v-btn>
      <v-btn color="blue darken-1" text @click="saveCategories(productPost.id, selectedCategoriesLocal); show = false">{{$t('common.save')}}</v-btn>
    </v-card-actions>
    </div>
  </v-dialog>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    categories: {
      type: Array
    },
    selectedCategories: {
      type: Array
    },
    productPost: {
      type: Object
    },
    value: Boolean
  },

  name: "custom-page",
  data() {
    return {
      tabIndex: 0,
      openOnFocus: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Category Picker" }]);
  },
  methods: {
    saveCategories(shopPostId, categoryIds) {
      this.loading = true;
      ApiService.put(`api/shop-posts/${shopPostId}/categories?ids=${categoryIds}`)
          .then(response => {
            this.$log.debug("Categories updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {this.loading = false});
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    selectedCategoriesLocal: {
      get(){
        return this.selectedCategories
      },
      set(newSelectedCats){
        this.$emit("update-selectedCategories", newSelectedCats);
      }
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
</script>
